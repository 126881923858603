import { yupResolver } from '@hookform/resolvers/yup'
import { Button, DialogActions, Divider } from '@mui/material'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { ControlledInput } from '../../common/Inputs'
import { useInputHandlers } from '../../hooks/useInputHandlers'
import { closeModal } from '../../redux/modal-slice'
import { useAddSupportTable, useUpdateSupportTable } from './supportTableApiHooks'
import { validationSchema } from './validationSchema'

const SupportTableForm = ({ initData, supportType, action, mutationOptions }) => {
  const dispatch = useDispatch()

  const {
    watch,
    control,
    setError,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({ initialValues: { name: '' }, reValidateMode: 'onBlur', validationSchema: yupResolver(validationSchema) })

  const { onChange } = useInputHandlers(setValue)

  useEffect(() => {
    watch()
  }, [])

  useEffect(() => {
    setValue('name', initData.name)
  }, [initData])

  const closeAddModal = () => dispatch(closeModal('support_table_form'))

  const { mutate: addSupportTable, isError, error } = useAddSupportTable(supportType, mutationOptions, closeAddModal)
  const {
    mutate: updateSupportTable,
    isError: isUpdatingError,
    error: updatingError,
  } = useUpdateSupportTable(supportType, mutationOptions, closeAddModal)

  useEffect(() => {
    if (isError || isUpdatingError) {
      const validationError = (error ?? updatingError).response.data.message['name']

      if (validationError) {
        setError('name', { message: validationError, type: 'required' })
      }
    }
  }, [isError, error, isUpdatingError, updatingError])

  const handleSave = handleSubmit(() => {
    const { name } = getValues()

    if (action === 'create') {
      addSupportTable({ name, supportTypeId: supportType })
    } else if (action === 'update') {
      if (initData.id) {
        updateSupportTable({ name, id: initData.id })
      }
    }
  })

  return (
    <>
      <ControlledInput
        control={control}
        id='name'
        name='name'
        label='Support Table'
        type='text'
        onChange={onChange}
        error={errors.name}
        fullWidth
        autoFocus
        size='small'
        variant='standard'
        margin='dense'
      />
      <Divider />
      <DialogActions>
        <Button disabled={!getValues().name} onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </>
  )
}

export { SupportTableForm }
