import { createSlice } from '@reduxjs/toolkit'

const themeState = {
  mode: 'light',
}

const ThemeSlice = createSlice({
  name: 'theme',
  initialState: themeState,
  reducers: {
    changeTheme: (state, action) => {
      localStorage.setItem('mode', action.payload)
      state.mode = action.payload
    },
  },
})

export const { changeTheme } = ThemeSlice.actions

export default ThemeSlice.reducer
