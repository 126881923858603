import { InputAdornment, TextField } from '@mui/material'
import PropTypes from 'prop-types'

const CustomInput = ({
  id,
  name,
  label = '',
  type = 'text',
  value,
  required = false,
  error = '',
  helperText,
  onChange = () => {},
  onBlur,
  variant = 'standard',
  startAdornment = '',
  endAdornment = '',
  fullWidth = true,
  margin,
  autoFocus = false,
  sx = {},
  multiline = false,
  rows = 1,
  autocomplete = false,
  disabled = false,
  min,
  max,
}) => {
  return (
    <TextField
      autoComplete={autocomplete ? name : undefined}
      id={id}
      name={name}
      label={label}
      value={value}
      error={Boolean(error)}
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
      autoFocus={autoFocus}
      required={required}
      helperText={helperText}
      variant={variant}
      type={type}
      margin={margin || 'none'}
      fullWidth={fullWidth}
      size='small'
      sx={sx}
      min={min}
      max={max}
      slotProps={{
        htmlInput: {
          min,
          max,
        },
        input: {
          startAdornment: startAdornment && <InputAdornment position='start'>{startAdornment}</InputAdornment>,
          endAdornment: endAdornment && <InputAdornment position='end'>{endAdornment}</InputAdornment>,
        },
        inputLabel: {
          sx: { paddingLeft: startAdornment ? '16px' : '0px', color: 'label' },
          shrink: value || value === 0 ? true : false,
        },
        formHelperText: {
          color: error ? 'error' : 'primary',
        },
      }}
      multiline={multiline}
      rows={rows}
    />
  )
}

CustomInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  select: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  hint: PropTypes.string,
  error: PropTypes.string,
  errorMessage: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  isValid: PropTypes.bool,
  options: PropTypes.array,
  startAdornment: PropTypes.string,
  endAdornment: PropTypes.string,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
}

export { CustomInput }
