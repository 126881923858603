export const useReactFormUtils = ({ setError }) => {
  const setValidationErrors = (data) => {
    console.log('data', data)
    // const validationError = (error ?? updatingError).response.data.message['name']
    Object.keys(data).map((key) => {
      console.log('key', key)
      setError(key, { message: data[key] })
    })
  }

  return {
    setValidationErrors,
  }
}
