import PropTypes from 'prop-types'
import React from 'react'
import { CustomInput } from '../CustomInput'
import { Controller } from 'react-hook-form'

export const ControlledInput = ({ control, name, error, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <CustomInput {...field} {...props} name={name} value={field.value} error={error} helperText={error ? error.message : props.helperText} />
      )}
    />
  )
}

ControlledInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.object,
}
