import { Close as CloseIcon, Remove as RemoveIcon } from '@mui/icons-material'
import { alpha, Button, styled, useMediaQuery, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import Draggable from 'react-draggable'
import { useDispatch } from 'react-redux'
import { closeModal, minimizeModal } from '../../redux/modal-slice'

const ModalTemplate = ({ Component, title, id, size, isHidden, modalTop, modalLeft, minHeight, fullScreen }) => {
  const dispatch = useDispatch()
  const modalRef = useRef(null)

  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'))

  const minimize = (e) => dispatch(minimizeModal(id))

  const close = () => dispatch(closeModal(id))

  return (
    <>
      <Draggable nodeRef={modalRef} handle='#draggable-dialog-title' disabled={smallScreen || fullScreen} cancel='.action-button'>
        <Container
          sx={(theme) => ({
            top: smallScreen || fullScreen ? theme.options.appBar.height : modalTop ? `${modalTop}vh` : 'auto',
            left: smallScreen || fullScreen ? 0 : modalLeft === 'auto' || !modalLeft ? 'auto' : `${modalLeft}vw`,
            minHeight: minHeight ? minHeight : 'auto',
            visibility: isHidden ? 'hidden' : 'visible',
            transform: smallScreen || fullScreen ? 'translate( 0px, 0px) !important' : 'translate(-50%, -50%)', // override react draggable on full screen
            height: smallScreen || fullScreen ? '100%' : 'auto',
            maxHeight: smallScreen || fullScreen ? '100%' : 'calc(100% - 228px)',
            maxWidth: smallScreen || fullScreen ? '100%' : theme.breakpoints.values[size],
            width: '100%',
          })}
          ref={modalRef}>
          <Header id='draggable-dialog-title'>
            <TitleContainer>{title}</TitleContainer>
            <div>
              <Button
                className='action-button'
                onClick={minimize}
                sx={(theme) => ({
                  marginRight: '4px',
                  backgroundColor: theme.palette.primary[theme.mode],
                  minWidth: '40px',
                })}>
                <RemoveIcon />
              </Button>
              <Button className='action-button' onClick={close} sx={{ minWidth: '40px' }}>
                <CloseIcon />
              </Button>
            </div>
          </Header>
          <BodyContainer>{Component}</BodyContainer>
        </Container>
      </Draggable>
    </>
  )
}

ModalTemplate.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  isHidden: PropTypes.bool,
  modalTop: PropTypes.number,
  modalLeft: PropTypes.number,
  minHeight: PropTypes.number,
  fullScreen: PropTypes.bool,
}

ModalTemplate.defaultProps = { size: 'sm', fullScreen: false }

const Container = styled('div')(({ theme }) => {
  return {
    position: 'absolute',
    pointerEvents: 'all',
    borderRadius: '5px',
    background: `${theme.palette.modal[theme.mode]}`,
    boxShadow: '2px 4px 18px 0px rgba(0,0,0,0.75)',
    display: 'flex',
    flexDirection: 'column',
  }
})

const Header = styled('div')(() => {
  return {
    padding: '10px',
    boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.25)',
    cursor: 'move',
    userSelect: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0.75rem',
  }
})

const TitleContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  paddingRight: '1rem',
}))

const BodyContainer = styled('div')(({ theme }) => ({
  '::-webkit-scrollbar': {
    width: '5px',
  },
  '::-webkit-scrollbar-track': {
    background: 'transparent',
  },

  '::-webkit-scrollbar-thumb': {
    backgroundColor: alpha(theme.palette.primary[theme.mode], 1),
    borderRadius: '20px',
    border: 'transparent',
  },
  padding: '10px',
  overflowY: 'auto',
}))

export { ModalTemplate }
