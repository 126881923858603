import * as dayjs from 'dayjs'

export const useInputHandlers = (setter) => {
  const handleChange = (e) => {
    const { name, value } = e.target

    setter(name, value)
  }
  const handleInputChange = (e) => {
    handleChange(e)
  }

  const handleSelectChange = (e, value, name, multiple) => {
    if (multiple) {
      const values = value.map((v) => v.value || v.id)
      handleChange({ ...e, target: { name, value: values } })
    } else {
      handleChange({ ...e, target: { name, value: value?.id ?? value?.value ?? null } })
    }
  }

  const handleDateChange = (value, name) => {
    if (dayjs.isDayjs(value)) {
      value = value.format('YYYY-MM-DD')
    }

    handleChange({ target: { name, value: value ?? null } })
  }

  const handleToggleChange = (e, value, name) => {
    handleChange({ ...e, target: { name, value } })
  }

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target

    handleChange({ target: { name, value: checked } })
  }

  return {
    onChange: handleInputChange,
    onSelectChange: handleSelectChange,
    onDateChange: handleDateChange,
    onToggleChange: handleToggleChange,
    onSwitchChange: handleSwitchChange,
  }
}
