import PropTypes from 'prop-types'
import React from 'react'
import { Controller } from 'react-hook-form'
import { CustomTooltip } from '../../Tooltip/CustomTooltip'
import { NumberRangePicker } from '../NumberRangePicker'

export const ControlledNumberRangePicker = ({ control, name, min, label, onChange }) => {
  return (
    <CustomTooltip title={label} placement='top' arrow>
      <div>
        <Controller
          control={control}
          name={name}
          render={({ field }) => <NumberRangePicker {...field} min={min} label={label} onChange={onChange} />}
        />
      </div>
    </CustomTooltip>
  )
}

ControlledNumberRangePicker.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  min: PropTypes.number,
  label: PropTypes.string,
}
