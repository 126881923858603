import { Box } from '@mui/material'
import React from 'react'
import { CustomInput } from './CustomInput'

export const NumberRangePicker = ({ name, min, label, onChange, value = { from: '', to: '' } }) => {
  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <CustomInput
        fullWidth
        type='number'
        label={`${label} From`}
        value={value.from}
        name={`${name}`}
        onChange={(e) => onChange({ ...e, target: { name: e.target.name, value: { ...value, from: e.target.value } } })}
        min={min}
        max={value.to}
        size='small'
      />
      <CustomInput
        fullWidth
        type='number'
        label={`${label} To`}
        value={value.to}
        name={`${name}`}
        onChange={(e) => onChange({ ...e, target: { name: e.target.name, value: { ...value, to: e.target.value } } })}
        min={value.from}
        size='small'
      />
    </Box>
  )
}
