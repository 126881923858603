import { styled } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from './Modal'
import { ModalTabBar } from './ModalTabBar'

const GlobalModal = () => {
  const { modal } = useSelector((state) => ({
    modal: state.GlobalModal.modals,
  }))

  const [minimized, setMinimized] = useState(false)

  useEffect(() => {
    Object.values(modal).forEach((i) => {
      if (i.type === 'minimized') {
        setMinimized(true)
      }
    })

    if (!Object.values(modal).length) {
      setMinimized(false)
    }
  }, [modal])

  return (
    <>
      <StyledGlobalModal>
        <StyledModalContainer>
          <Modal />
        </StyledModalContainer>
        {minimized && (
          <StyledModalTabBar>
            <ModalTabBar />
          </StyledModalTabBar>
        )}
      </StyledGlobalModal>
    </>
  )
}

const StyledGlobalModal = styled('div')(({ theme }) => ({
  zIndex: theme.options.modal.zIndex,
  position: 'fixed',
  height: '100vh',
  width: '100vw',
  display: 'grid',
  pointerEvents: 'none',
  gridTemplateRows: '30fr 1fr',
  gridTemplateAreas: "'modal' 'tabBar'",
}))

const StyledModalContainer = styled('div')(() => ({ display: 'grid', gridArea: 'modal', justifyItems: 'center', alignItems: 'center' }))

const StyledModalTabBar = styled('div')(({ theme }) => ({
  gridArea: 'tabBar',
  display: 'flex',
  pointerEvents: 'auto',
  padding: '5px',
  paddingLeft: '1rem',
  zIndex: '9999999 !important',
}))

export { GlobalModal }
