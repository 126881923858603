import { createSlice } from '@reduxjs/toolkit'

const initState = { modals: {} }

const ModalSlice = createSlice({
  name: 'globalModal',
  initialState: initState,
  reducers: {
    setModal: (state, action) => {
      state.modals = { ...state.modals, [action.payload.id]: action.payload }
    },
    closeModal: (state, action) => {
      const oldModals = state.modals
      delete oldModals[action.payload]

      state.modals = { ...oldModals }
    },
    minimizeModal: (state, action) => {
      state.modals = {
        ...state.modals,
        [action.payload]: {
          ...state.modals[action.payload],
          type: 'minimized',
        },
      }
    },
    maximizeModal: (state, action) => {
      state.modals = {
        ...state.modals,
        [action.payload]: {
          ...state.modals[action.payload],
          type: 'maximized',
        },
      }
    },
  },
})

export const { setModal, closeModal, minimizeModal, maximizeModal } = ModalSlice.actions

export default ModalSlice.reducer
