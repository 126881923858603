import { styled } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import { maximizeModal } from '../../redux/modal-slice'

const ModalTabBar = () => {
  const { modal } = useSelector((state) => ({
    modal: state.GlobalModal.modals,
  }))

  const dispatch = useDispatch()

  return (
    <>
      {Object.values(modal).map((mod) => {
        if (mod.type === 'minimized') {
          return (
            <StyledModalTabBar
              sx={{ '&:hover': { cursor: 'pointer' } }}
              key={mod.id}
              onKeyDown={() => {}}
              onClick={() => dispatch(maximizeModal(mod.id))}>
              {mod.name}
            </StyledModalTabBar>
          )
        }
        return
      })}
    </>
  )
}

const StyledModalTabBar = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary[theme.mode],
  padding: '5px 10px',
  borderRadius: '5px',
}))

export { ModalTabBar }
