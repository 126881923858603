import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import React, { useState } from 'react'

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontWeight: 'bold',
    '& .MuiTooltip-arrow': {
      color: 'common.white', // Arrow color
    },
  },
}))

export const CustomTooltip = ({ children, title, subtitle, sx = {}, arrow = false }) => {
  const [open, setOpen] = useState(false)

  return (
    <LightTooltip
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      open={open}
      title={
        <div>
          <div>{title}</div>
          {subtitle && <div style={{ fontSize: '0.8em', opacity: 0.8 }}>{subtitle}</div>}
        </div>
      }
      placement='top'
      arrow={arrow}
      sx={sx}>
      {children}
    </LightTooltip>
  )
}
