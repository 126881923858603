import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

const CustomSelect = ({
  multiple = false,
  label,
  onChange,
  value,
  name,
  disabled = false,
  disableCloseOnSelect = false,
  mandatoryOptions = [],
  options = [],
  variant,
  helperText,
  sx = {},
  error,
  onBlur = () => {},
}) => {
  useEffect(() => {
    // set value to mandatory options if multiple
    if (multiple && mandatoryOptions?.length) {
      onChange({ target: { name, value: mandatoryOptions } }, mandatoryOptions, name, multiple)
    }
  }, [mandatoryOptions])

  const getSelectedValue = React.useCallback(() => {
    if (!options) {
      return []
    }

    if (multiple) {
      // Ensure mandatory options are always included
      const selectedValues = options.filter((option) => value.includes(option.id) || value.includes(option.value))
      // Combine and deduplicate
      return Array.from(new Set([...mandatoryOptions, ...selectedValues]))
    } else {
      return options.find((option) => option.value === value || option.id === value) || null
    }
  }, [multiple, value, options, mandatoryOptions])

  return (
    <Autocomplete
      multiple={multiple}
      size='small'
      name={name}
      onChange={(event, newValue) => {
        if (multiple && mandatoryOptions?.length) {
          // Ensure mandatory options are always included
          const allValues = Array.from(new Set([...mandatoryOptions, ...newValue]))
          onChange(event, allValues, name, multiple)
        } else {
          onChange(event, newValue, name, multiple)
        }
      }}
      onBlur={onBlur}
      options={options}
      getOptionLabel={(option) => option.name || option.label || ''}
      sx={sx}
      value={getSelectedValue()}
      filterSelectedOptions
      disableCloseOnSelect={disableCloseOnSelect}
      ChipProps={{ size: 'small' }}
      disabled={disabled}
      fullWidth
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index })
          return (
            <Chip
              key={key}
              label={option.name || option.label || ''}
              {...tagProps}
              disabled={mandatoryOptions.includes(option)}
              sx={{
                backgroundColor: mandatoryOptions.includes(option) ? 'disabled.main' : 'inherit',
                color: 'text.primary',
                pointerEvents: disabled ? 'none' : 'auto',
              }}
            />
          )
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          slotProps={{
            inputLabel: {
              color: 'label',
            },
            formHelperText: {
              color: error ? 'error' : 'primary',
            },
          }}
          helperText={helperText || ''}
          error={error}
          label={label}
          size='small'
          variant={variant}
        />
      )}
    />
  )
}

CustomSelect.propTypes = {
  multiple: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.any,
  name: PropTypes.string,
  disableCloseOnSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  mandatoryOptions: PropTypes.array,
  variant: PropTypes.string,
  helperText: PropTypes.string,
  sx: PropTypes.object,
  error: PropTypes.bool,
  onBlur: PropTypes.func,
}

export { CustomSelect }
