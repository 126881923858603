import { Box, Button, Divider, Grid2 as Grid, Typography } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { ControlledDatePicker, ControlledInput, ControlledSelect } from '../../../common/Inputs'
import { CREATE_INVENTORY } from '../../../constants/ModalNames'
import { MAKES, MODELS, PREVIOUS_OWNERS, VENDORS, YEARS } from '../../../constants/SupportTypes'
import { useInputHandlers } from '../../../hooks/useInputHandlers'
import { useReactFormUtils } from '../../../hooks/useReactFormUtils'
import { closeModal } from '../../../redux/modal-slice'
import { useSupportTableOptions } from '../../SupportTable/supportTableApiHooks'
import { useAddInventory } from '../inventoryApiHooks'
import { inventorySchema } from '../types/schema'

const AddNewInventoryModal = () => {
  const { openModals } = useSelector((state) => ({ openModals: state.GlobalModal.modals || {} }))
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const {
    control,
    trigger,
    getValues,
    setValue,
    setError,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      vin: '',
      purchaseDate: null,
      previousOwnerId: null,
      yearId: null,
      makeId: null,
      modelId: null,
      purchasePrice: null,
    },
    reValidateMode: 'onBlur',
    resolver: yupResolver(inventorySchema),
  })

  const { setValidationErrors } = useReactFormUtils({ setError })

  const onSuccessAddNewInventory = (id) => {
    dispatch(closeModal(CREATE_INVENTORY))
    navigate(`${location.pathname}/${id}`)
  }

  const { data: supportTableOptions = {} } = useSupportTableOptions([PREVIOUS_OWNERS, YEARS, MAKES, MODELS])
  const { mutate: addInventory, error: addInventoryErrors } = useAddInventory(onSuccessAddNewInventory)

  const values = getValues()

  const { onChange, onSelectChange, onDateChange } = useInputHandlers(setValue)

  const handleSave = handleSubmit((data) => {
    addInventory(data)
    // updateTitleMutation({ ...data, id: params.inventoryId })
  })

  const currentLocation = useMemo(() => {
    return location.pathname
  }, [])

  useEffect(() => {
    if (addInventoryErrors) {
      setValidationErrors(addInventoryErrors.response.data.message)
    }
  }, [addInventoryErrors])

  useEffect(() => {
    if (Object.keys(openModals).includes(CREATE_INVENTORY) && currentLocation != location.pathname) {
      dispatch(closeModal(CREATE_INVENTORY))
    }
  }, [location, openModals])

  return (
    <>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 12, md: 4 }}>
          <ControlledInput
            control={control}
            autoFocus
            id='vin'
            label='VIN'
            onChange={onChange}
            value={values?.vin}
            type='text'
            fullWidth
            variant='outlined'
            name='vin'
            error={errors.vin}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 4 }}>
          <ControlledSelect
            control={control}
            label='Previous Owner'
            onChange={(e, value) => onSelectChange(e, value, 'previousOwnerId')}
            value={values?.previousOwnerId ?? null}
            name={'previousOwnerId'}
            options={supportTableOptions?.PreviousOwners || []}
            variant='outlined'
            error={errors.previousOwnerId}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 4 }}>
          <ControlledSelect
            control={control}
            label='Year'
            onChange={(e, value) => onSelectChange(e, value, 'yearId')}
            value={values?.yearId || null}
            name={'yearId'}
            options={supportTableOptions?.Years || []}
            variant='outlined'
            error={errors.yearId}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 4 }}>
          <ControlledSelect
            control={control}
            label='Make'
            onChange={(e, value) => onSelectChange(e, value, 'makeId')}
            value={values?.makeId ?? null}
            name={'makeId'}
            options={supportTableOptions?.Makes || []}
            variant='outlined'
            error={errors.makeId}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 4 }}>
          <ControlledSelect
            control={control}
            label='Model'
            onChange={(e, value) => onSelectChange(e, value, 'modelId')}
            value={values?.modelId ?? null}
            name={'modelId'}
            options={supportTableOptions?.Models || []}
            variant='outlined'
            error={errors.modelId}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 4 }}>
          <ControlledInput
            control={control}
            autoFocus
            id='purchasePrice'
            label='Purchase Price'
            onChange={onChange}
            value={values?.purchasePrice}
            startAdornment='$'
            type='number'
            fullWidth
            variant='outlined'
            name='purchasePrice'
            error={errors.purchasePrice}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 4 }}>
          <ControlledDatePicker
            validate={trigger}
            control={control}
            name='purchaseDate'
            value={values?.purchaseDate}
            label='Purchase Date'
            onChange={(value) => onDateChange(value, 'purchaseDate')}
            variant='outlined'
            clearable
            fullWidth
            error={errors.purchaseDate}
          />
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />
      <Typography variant='caption' color='textSecondary'>
        Stock number will be automatically generated.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={handleSave}>Save</Button>
      </Box>
    </>
  )
}

export { AddNewInventoryModal }
