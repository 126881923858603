import axios from 'axios'

export const setAuthToken = (token) => {
  if (token) {
    // Apply token to every request
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
  } else {
    // Delete auth header
    delete axios.defaults.headers.common['Authorization']
  }
}
