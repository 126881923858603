import React from 'react'
import { useWebsocketNotifications } from '../../hooks/useWebsocketNotifications'

/**
 * This component is used to connect to the websocket notifications
 * also makes query client available thru app context
 */
export const WebsocketNotificationsProvider = () => {
  useWebsocketNotifications()
  return <></>
}
