import { Box, TextField } from '@mui/material'
import React from 'react'
import { CustomDatePicker } from './CustomDatePicker'

export const DateRangePicker = ({ label, onChange, name, value = { from: null, to: null } }) => {
  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <CustomDatePicker
        label={`${label} Start Date`}
        name={name}
        value={value.from}
        onChange={(newValue) => onChange({ ...value, from: newValue })}
        renderInput={(params) => <TextField {...params} />}
        maxDate={value.to} // Prevents selecting start date after end date
      />
      <CustomDatePicker
        label={`${label} End Date`}
        name={name}
        value={value.to}
        onChange={(newValue) => onChange({ ...value, to: newValue })}
        renderInput={(params) => <TextField {...params} />}
        minDate={value.from} // Prevents selecting end date before start date
      />
    </Box>
  )
}
