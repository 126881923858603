// socket events
export const NOTIFICATION_EVENT = {
  WELCOME: 'welcome', // welcome message
  CONNECTED: 'connected', // welcome message

  // quote notifications
  QUOTE_HOLD_APPROVED_EMAIL_SENT: 'quote__HoldApprovedEmailSent',
  QUOTE_APPROVED_EMAIL_SENT: 'quote__ApprovedEmailSent',
  QUOTE_CLOSE_APPROVED_EMAIL_SENT: 'quote__CloseApprovedEmailSent',

  QUOTE_WORKFLOW_STEP_CHANGED: 'quote__WorkflowStepChanged',
  QUOTE_CREATED: 'quote__Created',

  QUOTE_HOLD_REQUESTED: 'quote__HoldRequested',

  QUOTE_HOLD_APPROVED: 'quote__HoldApproved',
  QUOTE_HOLD_REJECTED: 'quote__HoldRejected',

  QUOTE_REQUESTED: 'quote__Requested',

  QUOTE_ACCEPTED: 'quote__Accepted',
  QUOTE_REJECTED: 'quote__Rejected',

  QUOTE_CLOSE_REQUESTED: 'quote__CloseRequested',

  QUOTE_CLOSE_APPROVED: 'quote__CloseApproved',
  QUOTE_CLOSE_REJECTED: 'quote__CloseRejected',
}

export const NotificationPriority = {
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3,
}
