import { io } from 'socket.io-client'

export class WebsocketService {
  constructor() {
    this.socket = null
    this.connecting = false
  }

  /**
   * Connects to the WebSocket server.
   * @param {string} url - The WebSocket server URL.
   * @returns {Promise} - Resolves with the WebSocket instance.
   */
  connectSocket({ url, data }) {
    return new Promise((resolve, reject) => {
      const token = sessionStorage.getItem('TSToken')

      if (!token) {
        return reject(new Error('No token found in session storage.'))
      }

      // Initialize the socket if it doesn't already exist
      if (!this.socket && !this.connecting && !this.isConnected()) {
        this.connecting = true

        this.socket = io(url, {
          transports: ['websocket'],
          withCredentials: true,
          query: {
            token: `Bearer ${token}`,
            ...data,
          },
        })

        // Handle connection events
        this.socket.on('connect', () => {
          this.connecting = false
          resolve(this.socket)
        })

        this.socket.on('connect_error', (err) => {
          this.connecting = false
          this.socket = null // Reset socket on failure
          reject(err)
        })
      } else {
        // Socket already exists, resolve immediately
        this.connecting = false
        resolve(this.socket)
      }
    })
  }

  /**
   * Disconnects from the WebSocket server.
   */
  disconnectSocket() {
    if (this.socket) {
      this.socket.disconnect()
      console.log('WebSocket disconnected.')
      this.socket = null // Reset the socket
    }
  }

  /**
   * Checks if the WebSocket is connected.
   * @returns {boolean} - True if connected, false otherwise.
   */
  isConnected() {
    return Boolean(this?.socket?.connected)
  }
}
