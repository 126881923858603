import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'

export const ProtectedRoute = ({ isAuth, children }) => {
  const location = useLocation()

  if (!isAuth) {
    return <Navigate to='/login' replace state={{ from: location }} />
  }

  return children
}

ProtectedRoute.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}
