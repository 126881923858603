import PropTypes from 'prop-types'
import React from 'react'
import { Controller } from 'react-hook-form'
import { CustomSwitch } from '../CustomSwitch'

export const ControlledSwitch = ({ name, control, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <CustomSwitch
            {...field}
            {...props}
            name={name}
            checked={field?.value ?? props.checked ?? false}
            error={error}
            helperText={error ? error.message : props.helperText}
          />
        )
      }}
    />
  )
}

ControlledSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
}
