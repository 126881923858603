import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getModal } from './modalsArray'
import { ModalTemplate as Template } from './ModalTemplate'

const Modal = () => {
  const { modal } = useSelector((state) => ({
    modal: state.GlobalModal.modals,
  }))

  const [, setModals] = useState({})

  useEffect(() => {
    setModals(modal)
  }, [modal])

  return (
    <>
      {Object.values(modal).map((mod) => {
        const ModalComponent = getModal(mod.id).Component
        if (mod.type !== 'minimized') {
          return (
            // <Portal>
            <Template
              key={mod.id}
              Component={<ModalComponent {...mod.props} />}
              title={mod.name}
              size={getModal(mod.id).size}
              modalTop={getModal(mod.id).modalTop}
              modalLeft={getModal(mod.id).modalLeft}
              minHeight={getModal(mod.id).minHeight}
              onCloseModalClick={mod.props && mod.props.onCloseModalClick && mod.props.onCloseModalClick}
              id={mod.id}
              fullScreen={getModal(mod.id).fullScreen}
            />
            // </Portal>
          )
        } else {
          return (
            // <Portal>
            <Template
              key={mod.id}
              Component={<ModalComponent {...mod.props} />}
              isHidden
              title={mod.name}
              size={getModal(mod.id).size}
              modalTop={getModal(mod.id).modalTop}
              modalLeft={getModal(mod.id).modalLeft}
              minHeight={getModal(mod.id).minHeight}
              onCloseModalClick={mod.props && mod.props.onCloseModalClick && mod.props.onCloseModalClick}
              id={mod.id}
              fullScreen={getModal(mod.id).fullScreen}
            />
            // </Portal>
          )
        }
      })}
    </>
  )
}

export { Modal }
