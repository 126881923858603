import { Backdrop, CircularProgress, Stack, Typography } from '@mui/material'

const Loader = ({ isOpen = true, message = 'Loading...', value = 0 }) => {
  return (
    <Backdrop sx={{ zIndex: 9999 }} open={isOpen}>
      <Stack justifyContent='center' alignItems='center' spacing={2}>
        <CircularProgress color='inherit' value={value} />
        <Typography textAlign='center' variant='h6'>
          {message}
        </Typography>
      </Stack>
    </Backdrop>
  )
}

export { Loader }
