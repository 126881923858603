import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'

export const NotificationsQueryKey = {
  notifications: 'notifications',
  notificationsInformation: 'notifications-information',
}

const getUserNotificationsInformation = async () => {
  return axios.get(`/notifications/information`)
}

export const useUserNotificationsInformation = () => {
  return useQuery(['notifications-information'], () => getUserNotificationsInformation(), {
    refetchOnWindowFocus: false,
  })
}

const getUserNotifications = async (options) => {
  return axios.get(`/notifications`, { params: { options } })
}

export const useNotifications = (options) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  return useQuery([NotificationsQueryKey.notifications, options], () => getUserNotifications(options), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      queryClient.invalidateQueries([NotificationsQueryKey.notificationsInformation])
    },
  })
}

const updateAllNotifications = async (data) => {
  const { data: response } = await axios.put(`/notifications/all`, data)
  return response
}

const updateNotification = async (notificationId, data) => {
  const { data: response } = await axios.put(`/notifications/${notificationId}`, data)
  return response
}

export const useUpdateNotification = ({ callbacks = {} }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ notificationId, data }) => updateNotification(notificationId, data),
    onSuccess: (response) => {
      queryClient.invalidateQueries([NotificationsQueryKey.notificationsInformation])
      queryClient.invalidateQueries([NotificationsQueryKey.notifications], { exact: false })
      callbacks?.onSuccess?.(response)
    },
  })
}

export const useUpdateAllNotifications = ({ callbacks = {} }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateAllNotifications,
    onSuccess: (response) => {
      queryClient.invalidateQueries([NotificationsQueryKey.notifications])
      // queryClient.invalidateQueries([NotificationsQueryKey.notificationsInformation], { refetchActive: true })
      callbacks?.onSuccess?.(response)
    },
  })
}
