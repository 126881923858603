import { useQuery, useMutation, useQueryClient } from 'react-query'
import axios from 'axios'

const getAllSupportTypes = () => {
  return axios.get('/support-types')
}

const getSupportTablesPagination = (supportType, options) => {
  return axios.get(`/support-tables/${supportType}`, { params: { options } })
}

const addSupportTable = (data) => {
  return axios.post('/support-tables', { ...data })
}

const updateSupportTable = (supportTableData) => {
  return axios.patch(`/support-tables/${supportTableData.id}`, supportTableData)
}

const deleteSupportTables = (ids) => {
  return axios.delete('/support-tables', { params: { ids } })
}

export const useGetSupportTypes = () => {
  return useQuery('support-types', getAllSupportTypes, {
    refetchOnWindowFocus: false,
  })
}

export const useGetSupportTablePagination = (supportType, options) => {
  return useQuery(['support-tables', supportType, options], () => getSupportTablesPagination(supportType, options), {
    enabled: !!supportType,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })
}

export const useAddSupportTable = (supportType, options, callback) => {
  const queryClient = useQueryClient()
  return useMutation(addSupportTable, {
    onSuccess: () => {
      queryClient.invalidateQueries(['support-tables', supportType, options])
      callback && callback()
    },
  })
}

export const useUpdateSupportTable = (supportType, options, callback) => {
  const queryClient = useQueryClient()
  return useMutation(updateSupportTable, {
    onSuccess: () => {
      queryClient.invalidateQueries(['support-tables', supportType, options])
      callback && callback()
    },
  })
}

export const useDeleteSupportTables = (supportType, options, setSelectedRows) => {
  const queryClient = useQueryClient()
  return useMutation(deleteSupportTables, {
    onSuccess: () => {
      queryClient.invalidateQueries(['support-tables', supportType, options])
      setSelectedRows && setSelectedRows([])
    },
  })
}

const getSupportTableOptions = (ids) => {
  return axios.get('/support-types/support-tables-by-support-types', { params: { ids } })
}

export const useSupportTableOptions = (supportTypes) => {
  return useQuery(['support-tables', supportTypes], () => getSupportTableOptions(supportTypes), {
    refetchOnWindowFocus: false,
    select: (data) => {
      const newData = {}

      for (const item of data.data) {
        newData[item.name] = item.supportTables || []
      }

      return newData
    },
  })
}
