import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { AwsBucket } from '../../../constants/Aws.constants'
import { getFiles } from '../../../utils/getFiles'
import { toastNotification, toastType } from '../../../utils/toast'
import { TaskQueryKey } from '../../Task/taskApiHooks'

export const QuoteQueryKey = {
  QUOTE: 'quote',
  QUOTES: 'quotes',
  QUOTE_INVENTORIES: 'quote-inventories',
  QUOTE_DOCUMENTS: 'quote-documents',
}

/**
 * @typedef {'hold request' | 'hold approval' | 'hold reject' |
 *           'quote request' | 'quote approval' | 'quote reject' |
 *           'close request' | 'close approval' | 'close reject'} WorkflowAction
 */

/**
 * @typedef {Object} WorkflowData
 * @property {number} step
 * @property {WorkflowAction} action
 */

// Get Quote
const getQuote = (id) => {
  return axios.get(`/quotes/${id}`)
}
export const useGetQuote = (id) => {
  return useQuery(QuoteQueryKey.QUOTE, () => getQuote(id), {
    refetchOnWindowFocus: false,
  })
}

// Get Quote Inventories
const getQuoteInventories = (options, id) => {
  return axios.get(`/quotes/${id}/inventories`, { params: { options } })
}

export const useQuoteInventories = (options, id) => {
  return useQuery([QuoteQueryKey.QUOTE_INVENTORIES, options], () => getQuoteInventories(options, id), {
    refetchOnWindowFocus: false,
    enabled: Boolean(id),
  })
}

// Create Quote
const createQuote = (data) => {
  return axios.post('/quotes/create', data)
}

export const useCreateQuote = (callback) => {
  return useMutation(createQuote, {
    onSuccess: (data) => {
      callback && callback(data?.data?.id)
    },
  })
}

// Update Quote
const updateQuote = ({ data, id }) => {
  return axios.patch(`/quotes/${id}`, data)
}

export const useUpdateQuote = (callback) => {
  const queryClient = useQueryClient()
  return useMutation(updateQuote, {
    onSuccess: () => {
      queryClient.invalidateQueries(QuoteQueryKey.QUOTE)
      callback()
    },
  })
}

const cancelQuote = (data) => {
  return axios.post('/quotes/cancel', data)
}

export const useCancelQuote = (callback) => {
  const queryClient = useQueryClient()
  return useMutation(cancelQuote, {
    onSuccess: () => {
      queryClient.invalidateQueries(QuoteQueryKey.QUOTE)
      queryClient.invalidateQueries(TaskQueryKey.TASKS)
      queryClient.invalidateQueries(TaskQueryKey.QUOTES)
      callback?.onSuccess?.()
      toastNotification({ message: 'Quote cancelled successfully', type: toastType.success })
    },
    onError: (error) => {
      callback?.onError?.()
    },
  })
}

// Add Inventory to Quote
const lingInventoryWithQuote = ({ id, ...data }) => {
  return axios.post(`/quotes/${id}/link-inventories`, data.data)
}

export const useLinkInventoryWithQuote = (callback) => {
  const queryClient = useQueryClient()
  return useMutation(lingInventoryWithQuote, {
    onSuccess: () => {
      const queriesToInvalidate = [QuoteQueryKey.QUOTE, QuoteQueryKey.QUOTE_INVENTORIES]
      queriesToInvalidate.forEach((query) => queryClient.invalidateQueries(query))
      callback()
    },
  })
}

/**
 * @param {WorkflowData} data
 * @returns {Promise<void>}
 */
const workflowAction = (data) => {
  return axios.patch(`/quotes/${data.quoteId}/truck-sales/workflow`, data)
}

export const useWorkflowAction = (callback) => {
  const queryClient = useQueryClient()
  return useMutation(workflowAction, {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries(QuoteQueryKey.QUOTE)
      queryClient.invalidateQueries(TaskQueryKey.TASKS)
      callback?.()
    },
  })
}

const cloneQuote = (id) => {
  return axios.post(`/quotes/${id}/clone`)
}

export const useCloneQuote = (onSuccess) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(cloneQuote, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(QuoteQueryKey.QUOTE)
      const quoteId = response.data
      navigate(`/quote/${quoteId}`)
      onSuccess?.()
    },
  })
}

// Quote Documents
const getQuoteDocuments = (id) => {
  return axios.get(`/quotes/${id}/documents`)
}

export const useQuoteDocuments = (id) => {
  return useQuery([QuoteQueryKey.QUOTE_DOCUMENTS, id], () => getQuoteDocuments(id))
}

// quote document print
const getQuotePdfData = (id, data = {}) => {
  return axios.get(`/quotes/${id}/pdf`, { params: data })
}

export const useQuotePdfData = (id, data = {}) => {
  return useQuery([QuoteQueryKey.QUOTE_PDF_DATA, id], () => getQuotePdfData(id, data), { refetchOnWindowFocus: false, enabled: Boolean(id) })
}

const quoteDocumentPrint = (id, fileType, data) => {
  return getFiles({ url: `/quotes/${id}/download/${fileType}?bucket=${AwsBucket.documents}`, data }, { allowDownload: true })
}

export const useQuoteDocumentPrint = (callbacks) => {
  return useMutation(({ id, fileType, data }) => quoteDocumentPrint(id, fileType, data), {
    onSuccess: () => {
      callbacks?.onSuccess?.()
    },
  })
}

const deleteInventory = async (data) => {
  const response = await axios.post(`/quotes/delete-inventories`, data)
  return response.data
}

export const useDeleteInventory = (cb) => {
  const queryClient = useQueryClient()

  return useMutation(deleteInventory, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QuoteQueryKey.QUOTE_INVENTORIES)
      await queryClient.invalidateQueries(QuoteQueryKey.QUOTE)

      toastNotification({ message: 'Successfully deleted!', type: toastType.success })
      cb({ action: 'delete-inventory' })
    },
    onError: (error) => {
      if (error.response?.data?.message) {
        toastNotification({ message: error.response?.data?.message, type: toastType.error })
      }
    },
  })
}
