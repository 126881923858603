import PropTypes from 'prop-types'
import React from 'react'
import { Controller } from 'react-hook-form'
import { CustomSelect } from '../CustomSelect'

export const ControlledSelect = ({ name, control, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <CustomSelect
          {...field}
          {...props}
          onBlur={() => field.onBlur()} //force re validation on blur event
          name={name}
          value={field.value ?? ''}
          error={props.error}
          helperText={props.error ? props.error.message : props.helperText}
        />
      )}
    />
  )
}

ControlledSelect.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
}
