const inventoryProperties = [
  'id',
  'vin',
  'creationDate',
  'rowStatus',
  'stockNumber',
  'keyNumber',
  'dateIn',
  'mileage',
  'notesWebsite',
  'show',
  'retailPrice',
  'internalNotes',
  'horsepower',
  'engineBreak',
  'ecmMiles',
  'numberOfSpeed',
  'ration',
  'overdrive',
  'wheelbase',
  'frontAxleWeight',
  'rearAxleWeight',
  'numberOfBeds',
  'apu',
  'wholesalePrice',
  'transportationPrice',
  'repairCost',
  'original',
  'copy',
  'noTitle',
  'purchaseDate',
  'purchasePrice',
  'status',
  'salesPrice',
  'profit',

  'locationId',
  'previousOwnerId',
  'yearId',
  'makeId',
  'modelId',
  'colorId',
  'conditionId',
  'engineManufacturerId',
  'engineModelId',
  'transmissionId',
  'transmissionManufacturerId',
  'transmissionModelId',
  'driveId',
  'suspensionId',
  'numberOfRearAxlesId',
  'tiresId',
  'wheelsId',
  'grossVehicleWeightRatingId',
  'ctsTitleOptionsId',
  'vendorId',
]

// Utility function to map API response
export const mapApiResponseToInventory = (data) => {
  if (!data) return {}

  const mappedInventory = {}

  inventoryProperties.forEach((prop) => {
    if (prop in data) {
      // Handle date conversion if the property is a date
      if (prop === 'creationDate' || prop === 'dateIn' || prop === 'purchaseDate') {
        if (data[prop]) {
          mappedInventory[prop] = new Date(data[prop])
        } else {
          mappedInventory[prop] = null
        }
      } else {
        mappedInventory[prop] = data[prop] ?? null
      }
    }
  })

  return mappedInventory
}

export const setMultipleValuesReactForm = (values, setValue) => {
  Object.keys(values).forEach((key) => {
    setValue(key, values[key])
  })
}
