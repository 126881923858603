import axios from 'axios'
import { toastNotification, toastType } from './toast'
import { camelCaseToWords } from './common.utils'

export const setAxiosInterceptors = () => {
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (err) => {
      if (err.response.status >= 500) {
        toastNotification({ message: 'Server Error! Something went wrong.', type: toastType.error })
        return Promise.reject({}) //return empty object to avoid duplicate error in react query
      } else if (err.response.status >= 400 && err.response.status < 500) {
        const formatObjectMessage = (message) =>
          Object.entries(message)
            .map(([key, value]) => `${key}: ${value}`)
            .join('\n')

        const formatArrayMessage = (message) => message.map((item) => camelCaseToWords(item)).join('\n')

        const formatObjectArrayMessage = (message) => message.map((obj) => Object.values(obj)).flat()

        console.log('err', err.response.data)
        const responseData = err.response.data
        const message = responseData.message
        let errorMessage = ''

        // Handle nested message object case
        if (typeof message === 'object' && message.message && message.error) {
          // Use error for non-validation errors (like 404)
          errorMessage = message.message || message.error
        } else {
          // Handle validation errors and other cases
          errorMessage = Array.isArray(message)
            ? typeof message[0] === 'object'
              ? formatObjectArrayMessage(message)
              : formatArrayMessage(message)
            : typeof message === 'object'
            ? formatObjectMessage(message)
            : message
        }

        toastNotification({ message: errorMessage, type: toastType.error })

        return Promise.reject({}) //return empty object to avoid duplicate error in react query
      }

      return new Promise((_, reject) => {
        // reject(err)
      })
    }
  )
}
