import { configureStore } from '@reduxjs/toolkit'
import GlobalModalReducer from './modal-slice'
import ThemeReducer from './theme-slice'
import UserReducer from './user-slice'

export const store = configureStore({
  reducer: {
    Theme: ThemeReducer,
    User: UserReducer,
    GlobalModal: GlobalModalReducer,
  },
})
