import { Done, Error, Info, Warning } from '@mui/icons-material'
import React from 'react'
import { ToastContainer } from 'react-toastify'

export default function Toast({ type }) {
  const icon = React.useMemo(() => {
    switch (type) {
      case 'info':
        return <Info color='info' />
      case 'success':
        return <Done color='success' />
      case 'error':
        return <Error color='error' />
      case 'warning':
        return <Warning color='warning' />
      default:
        return undefined
    }
  }, [type])

  return <ToastContainer icon={icon} stacked />
}
