import { useQuery } from 'react-query'
import axios from 'axios'

export const TaskQueryKey = {
  TASKS: 'tasks',
}

const getTasks = (options = {}) => {
  return axios.get('/tasks', { params: { options } })
}

export const useTasks = (options) => {
  return useQuery([TaskQueryKey.TASKS, options], () => getTasks(options), {
    refetchOnWindowFocus: false,
  })
}
