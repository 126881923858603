import React from 'react'
import { toast } from 'react-toastify'

export const toastNotification = (data) =>
  toast(data.message, {
    type: data.type || 'success',
    position: data.position || 'top-right',
    autoClose: data.time || 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  })

export const toastType = {
  error: 'error',
  info: 'info',
  success: 'success',
  warning: 'warning',
}
